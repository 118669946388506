(function () {
	'use strict';

	angular
		.module('App')
		.controller('LoginController', LoginController);

	LoginController.$inject = ['$scope', '$state', '$ionicLoading', '$ionicPopup', '$http', '$translate', 'Model'];
	function LoginController($scope, $state, $ionicLoading, $ionicPopup, $http, $translate, Model) {
		$scope.form = 'login';
		$scope.data = {email:"",password:""};
		
		$scope.login = function() {
			var email = $scope.data.email,
				password = $scope.data.password;

			if("undefined" === typeof email){
				$scope.loginErrors = 'Votre email est requis.';

			}else if(!validateEmail(email)){
				$scope.loginErrors = 'Entrer une adresse email valide.';

			}else if(password == ""){
				$scope.loginErrors = 'Votre mots de passe est requis.';
			}else{
				$ionicLoading.show({
					templateUrl: 'templates/partials/loading.html',
				}).then(function() {
					Model.Users.login(email, password).then(function(data) {
						// Success! Logged in
						localStorage.setItem('token', angular.toJson(data.token));
						localStorage.setItem('user', angular.toJson(data.user));

						$state.go('updater');
					}, function(error) {
						// Shit happened
						if(error.code == 401){
							$scope.loginErrors = "Nom d'utilisateur ou mot de passe incorrect.";
						}
					}).finally(function() {
						$ionicLoading.hide();
					});
				});
			}
		};
		
		$scope.requestPassword = function() {
			var email = $scope.data.email;
			
			$ionicLoading.show({
				templateUrl: 'templates/partials/loading.html',
			}).then(function() {

				if("undefined" === typeof email){
				$scope.requestPasswordErrors = 'Votre email est requis.';
				$ionicLoading.hide();

				}else if(!validateEmail(email)){
					$scope.requestPasswordErrors = 'Entrer une adresse email valide.';
					$ionicLoading.hide();

				}else {
					Model.Users.requestPassword(email).then(function(data) {
						if (data) {
							$ionicPopup.alert({
								title: $translate.instant('NEW_PASSWORD_TITLE'),
								template: $translate.instant('NEW_PASSWORD_TEXT')
							});
						}
					}, function(error) {
						if(error.code == 404){
							$scope.requestPasswordErrors = "Adresse email non valide.";
						}

					}).finally(function() {
						$ionicLoading.hide();
					});
				}
			});
		};

		$scope.setForm = function(form) {
			$scope.form = form;
		};
		
		$scope.copyrightClick = function() {
			window.open('https://www.wacan.com/', '_system');
		};

		function validateEmail(email) {
		  var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		  return re.test(email);
		}
	}
})();