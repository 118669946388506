(function () {
	'use strict';

	angular
		.module('App')
		.factory('Users', Users);

	Users.$inject = ['$rootScope', '$q', '$http', '$fetchService'];
    function Users($rootScope, $q, $http, $fetchService) {
		var apiEndPoint = 'https://pce.api.wacan.com';
		
		return {
			login: function(email, password, lang) {
				lang = lang || $rootScope.language;
				var deferred = $q.defer();
				$http.post(apiEndPoint + '/user/login', {email: email, password: password, language: lang})
					.success(function(res) {
						if (res.error) {
							deferred.reject(res.error);
							return false;
						}
						deferred.resolve(res);
					})
					.error(function(err) {
						deferred.reject(err);
					});
				return deferred.promise;
			},
			requestPassword: function(email, lang) {
				lang = lang || $rootScope.language;
				var deferred = $q.defer();
				$http.post(apiEndPoint + '/user/requestPassword ', {email: email, language: lang})
					.success(function(res) {
						if (res.error) {
							deferred.reject(res.error);
							return false;
						}
						deferred.resolve(res);
					})
					.error(function(err) {
						deferred.reject(err);
					});
				return deferred.promise;
			},
			getMessages: function(id, lang, forceRemote) {
				lang = lang || $rootScope.language;
				return $fetchService.fetch(apiEndPoint + '/user/' + id + '/message', 'user-' + id + '-message-' + lang + '.json', lang, forceRemote);
			},
			getAssets: function(id, lang, forceRemote) {
				lang = lang || $rootScope.language;
				return $fetchService.fetch(apiEndPoint + '/user/' + id + '/file', 'user-' + id + '-file-' + lang + '.json', lang, forceRemote);
			}
		};
    }

})();