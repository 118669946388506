(function() {

'use strict';

	angular.module('ionic.contrib.drawer.vertical', ['ionic'])

	.controller('$ionDrawerVertical', function($scope, $element, $attrs, $document, $ionicGesture, $timeout, $ionicHistory, $ionDrawerVerticalDelegate, $ionicSlideBoxDelegate) {

		// We need closure
		var self = this;

		// Possible states the drawer can have
		var STATE_CLOSE = 'closed';
		var STATE_OPEN = 'opened';
		var STATE_DRAGGING = 'dragging';
		var STATE_DRAGGED = 'dragged';
		var STATE_ANIMATING = 'animating';

		// Possible directions the drawer may slide out to
		var DIRECTION_BOTTOM = 'bottom';
		var DIRECTION_TOP = 'top';
		var DIRECTION_LEFT = 'left';
		var DIRECTION_RIGHT = 'right';

		// Get state & direction
		// default: STATE_OPEN and DIRECTION_DOWN
		var state = ($attrs.state === STATE_CLOSE ? STATE_CLOSE : STATE_OPEN);
		var direction = DIRECTION_LEFT;
		var prevState = state; // Store previous state (limited to STATE_OPEN/STATE_CLOSE) as we'll need that later one, after having dragged the handle

		var threshold = 35;
		
		// Persist the state and direction on the wrapper
		// (needed for animations)
		var $wrapper = $element;
		$wrapper.addClass(state);

		$attrs.$observe('direction', function(value) {
			$wrapper.removeClass(direction);
			direction = value;
			$wrapper.addClass(direction);
			width = $wrapper[0].clientWidth;
			height = $wrapper[0].clientHeight;
		});
		
		// Height of the contents
		// Based on how much we dragged (compared to this height) we well close automatically or fall back to the opened state)
		var width = $wrapper[0].clientWidth;
		var height = $wrapper[0].clientHeight;

		var isTarget = function(el) {
			while(el) {
			  if(el === $element[0]) {
				return true;
			  }
			  el = el.parentNode;
			}
		  };

		// Delegate Stuff
		var deregisterInstance = $ionDrawerVerticalDelegate._registerInstance(
			self, $attrs.delegateHandle, function() {
				return $ionicHistory.isActiveScope($scope);
			}
		);
		$scope.$on('$destroy', function() {
			deregisterInstance();
		});

		// State functions
		var getState = function() {
			return state;
		};
		var isOpen = function() {
			return state == STATE_OPEN;
		};
		var isClosed = function() {
			return state == STATE_CLOSE;
		};
		var isBusyAnimating = function() {
			return state == STATE_ANIMATING;
		};
		var isBusyDragging = function() {
			return state == STATE_DRAGGING;
		};
		var isDoneDragging = function() {
			return state == STATE_DRAGGED;
		};
		this.getState = getState;
		this.isOpen = isOpen;
		this.isClosed = isClosed;
		this.isBusyDragging = isBusyDragging;
		this.isBusyAnimating = isBusyAnimating;

		// Open the drawer
		var open = function() {
			if ((isClosed() || isDoneDragging()) && !isBusyAnimating()) {
				$wrapper.attr('style', ''); // @note: this little trick will remove the inline styles
				state = STATE_ANIMATING;
				$wrapper.removeClass(STATE_CLOSE);
				$wrapper.addClass(STATE_OPEN + ' animate');
				$timeout(function() {
					$wrapper.removeClass('animate');
					state = prevState = STATE_OPEN;
				}, 400);
			}
		};
		this.openDrawer = open;

		// Close the drawer
		var close = function() {
			if ((isOpen() || isDoneDragging()) && !isBusyAnimating()) {
				$wrapper.attr('style', ''); // @note: this little trick will remove the inline styles
				state = STATE_ANIMATING;
				$wrapper.removeClass(STATE_OPEN);
				$wrapper.addClass(STATE_CLOSE + ' animate');
				$timeout(function() {
					$wrapper.removeClass('animate');
					state = prevState = STATE_CLOSE;
				}, 400);
			}
		};
		this.closeDrawer = close;

		// Toggle the drawer
		var toggle = function() {
			if (this.isOpen()) {
				this.closeDrawer();
			} else {
				this.openDrawer();
			}
		};
		this.toggleDrawer = toggle;

		var limitNumberBetween = function(number, min, max) {
			number = Math.min(max, number);
			number = Math.max(min, number);
			return number;
		};

		var startX = 0;
		var startY = 0;
		var startTarget = null;
		
		// Make the panel follow the cursor when dragging
		$ionicGesture.on('dragstart', ionic.DomUtil.animationFrameThrottle(function(e) {
			width = $wrapper[0].clientWidth;
			height = $wrapper[0].clientHeight;
			
			startX = e.gesture.touches[0].pageX;
			startY = e.gesture.touches[0].pageY;
			startTarget = e.target;
		}), $document);
		
		$ionicGesture.on('drag', ionic.DomUtil.animationFrameThrottle(function(e) {
			
			var horizontal = (direction == DIRECTION_LEFT || direction == DIRECTION_RIGHT);
			
			// Don't respond to drag if animating automatically
			if (isBusyAnimating()) return;

			// Store the current state (which is STATE_OPEN or STATE_CLOSE) for later
			if (!isBusyDragging()) prevState = state;

			if (!isTarget(e.target)) {
				if (direction == DIRECTION_LEFT && startX > threshold) {
					return;
				} else if (direction == DIRECTION_RIGHT && startX < width - threshold) {
					return;
				} else if (direction == DIRECTION_BOTTOM && startY < height- threshold) {
					return;
				} else if (direction == DIRECTION_TOP && startY > threshold) {
					return;
				}
			}
			
			// Update state to dragging
			state = STATE_DRAGGING;

			// The number of pixels we have dragged
			var deltaY = e.gesture.deltaY;
			var deltaX = e.gesture.deltaX;
			
			// Add or Subtract the height based on the direction of the previous state:
			// in some cases the drag position is relative to the bottom or top of the element
			// Also: don't overstretch!

			if (direction == DIRECTION_TOP) {
				if (prevState == STATE_CLOSE) {
					deltaY -= height;
				}
				deltaY = limitNumberBetween(deltaY, -height, 0);
			}
			if (direction == DIRECTION_BOTTOM) {
				if (prevState == STATE_CLOSE) {
					deltaY += height;
				}
				deltaY = limitNumberBetween(deltaY, 0, height);
			}
			if (direction == DIRECTION_LEFT) {
				if (prevState == STATE_CLOSE) {
					deltaX -= width;
				}
				deltaX = limitNumberBetween(deltaX, -width, 0);
			}
			if (direction == DIRECTION_RIGHT) {
				if (prevState == STATE_CLOSE) {
					deltaX += width;
				}
				deltaX = limitNumberBetween(deltaX, 0, width);
			}
			
			horizontal = (direction == DIRECTION_LEFT || direction == DIRECTION_RIGHT);
			
			// Make drawer follow it all
			if (horizontal) {
				$wrapper.css('transform', 'translate3d(' + deltaX + 'px,0,0)');
			} else {
				$wrapper.css('transform', 'translate3d(0,' + deltaY + 'px,0)');
			}

		}), $wrapper); //$document

		// Don't let the element hang in a semi-open state when done dragging
		$ionicGesture.on('dragend', function(e) {
			// Done dragging manually?
			if (isBusyDragging()) {

				startX = 0;
				startY = 0;
				startTarget = null;
			
				// Update state
				state = STATE_DRAGGED;

				if (direction == DIRECTION_BOTTOM) {

					var multiplier = (prevState == STATE_CLOSE) ? -1 : 1;

					
					// We dragged over 1/3rd of the panel height
					if (e.gesture.deltaY > multiplier * height / 3) {
						self.closeDrawer();
					}

					// We didn't drag over halfway
					else {
						self.openDrawer();
					}

				}

				else if (direction == DIRECTION_TOP) {

					var multiplier = (prevState == STATE_OPEN) ? -1 : 1;

					// We dragged over 1/3rd of the panel height
					if (e.gesture.deltaY < multiplier * height / 3) {
						self.closeDrawer();
					}

					// We didn't drag over halfway
					else {
						self.openDrawer();
					}

				}
				
				else if (direction == DIRECTION_LEFT) {
					
					var multiplier = (prevState == STATE_OPEN) ? -1 : 1;

					// We dragged over 1/3rd of the panel height
					if (e.gesture.deltaX < multiplier * width / 3) {
						self.closeDrawer();
					}

					// We didn't drag over halfway
					else {
						self.openDrawer();
					}
					
				}
				
				else if (direction == DIRECTION_RIGHT) {
					
					var multiplier = (prevState == STATE_OPEN) ? -1 : 1;

					// We dragged over 1/3rd of the panel height
					if (e.gesture.deltaX > Math.abs(multiplier * width / 3)) {
						self.closeDrawer();
					}

					// We didn't drag over halfway
					else {
						self.openDrawer();
					}
					
				}


			}

		}, $wrapper); //$document

	});

})();

(function() {

	angular.module('ionic.contrib.drawer.vertical')

	.directive('ionDrawerVerticalWrapper', function() {
		return {
			restrict: 'E',
			controller: '$ionDrawerVertical'
		};
	})

	.directive('ionDrawerVerticalHandle', function() {
		return {
			restrict: 'E'
		};
	});


})();

(function() {

	angular.module('ionic.contrib.drawer.vertical')

	.service('$ionDrawerVerticalDelegate', ionic.DelegateService([
		'openDrawer',
		'closeDrawer',
		'toggleDrawer',
		'getState'
	]));


})();