(function () {
	'use strict';

	angular
		.module('App')
		.controller('GalleryController', GalleryController);

	GalleryController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', '$ionicLoading', '$ionicPopup', '$ionicSlideBoxDelegate', '$timeout', '$ionicScrollDelegate', 'Modals', 'Model'];
	function GalleryController($scope, $rootScope, $state, $stateParams, $ionicLoading, $ionicPopup, $ionicSlideBoxDelegate, $timeout, $ionicScrollDelegate, Modals, Model) {
		// Sliders
		// Options par défaut pour le slider
		$scope.sliders = {};
		$scope.pageSliderOptions = {
			loop: false,
			pagination: false,
			iOSEdgeSwipeDetection: true,
			initialSlide: $scope.activeSlide,
			onSlideChangeStart: function(swiper) {
				$scope.activeSlide = swiper.activeIndex;
			}
		};
		
		// Scroll to top
		$scope.scrollToTop = function() {
			$ionicScrollDelegate.scrollTop();
		};

		$scope.$on("$ionicView.enter", function(event, data) {
			if (!$stateParams.item) {
				$rootScope.goHome();
				return;
			}
			$scope.viewHeight = window.innerHeight - $('.isotope-view .header').height();
			var user = angular.fromJson(localStorage.user);
			Model.Destinations.getSections(user.destination.id).then(function(sections) {
				var found = false;
				for (var i=0; i<sections.length; i++) {
					if (sections[i].id == data.stateParams.id) {
						loadItem(sections[i]);
						found = true;
						break;
					}
				}
				if (!found && data.stateParams.item) {
					loadItem(data.stateParams.item);
				}
			});
		});
		
		$scope.$on("$ionicView.afterEnter", function() {
			$timeout(function() {
				$scope.$broadcast('iso-option', {filter:'*'});
			}, 3000);
		});

		function loadItem(sections){
		// Filters
		$scope.filters = [];
		
		// Load data
		$scope.items = [];

			if (!$scope.items.length) {
				$ionicLoading.show({
					templateUrl: 'templates/partials/loading.html',
					duration: 3000
				}).then(function() {
					if (sections) {
						$scope.title = sections.name;
						
						// Filters
						$scope.filters.length = 0;
						sections.photoCategories = sections.photoCategories.filter(function(o){ return (o.status != 'deleted'); });
						sections.photoCategories.forEach(function(category) {
							$scope.filters.push(category);
						});
						
						// Items
						$scope.viewHeight = $('.layout-content').height();
						sections.photos = sections.photos.filter(function(o){ return (o.status != 'deleted'); });
						sections.photos.forEach(function(photo) {
							photo.width = Math.random() > 0.5 ? 380 : 190;
							photo.height = Math.floor($scope.viewHeight / 3) - 10;
						});

						$timeout(function() {
							$scope.items = sections.photos;
						});
					}

					// Reset filters
					$timeout(function() {
						$scope.$broadcast('iso-option', {filter:'*'});
					});
				});
			}
		}
		
		// Image modal
		$scope.showImage = function (item, index, $event) {
			$scope.modalImages = $scope.activeFilter ? $scope.items.filter(function(item) {
				return item.type === $scope.activeFilter;
			}) : $scope.items;
			// console.log('Show index ' + index + ' of', $scope.modalImages);
			$scope.modalIndex = index;
			$scope.modalVisible = true;
			
			$scope.slider.slideTo($scope.modalIndex);
		};
		$scope.modalVisible = false;
		$scope.modalSliderOptions = {
			loop: false,
			pagination: false
		};
		$scope.closeModal = function() {
			$scope.modalVisible = false;
		};
		$scope.$on("$ionicSlides.sliderInitialized", function(event, data){
			$scope.slider = data.slider;
		});
	}
})();