(function () {
	'use strict';

	angular
		.module('App')
		.directive('repeatBrick', repeatBrick)
		.directive('theFreewall', theFreewall);

	repeatBrick.$inject = [];
	function repeatBrick() {
		return function(scope, element, attrs) {
			if (scope.$last) {
				scope.$emit('LastBrick');
			}
		};
	}

	theFreewall.$inject = ['$rootScope', '$timeout'];
	function theFreewall($rootScope, $timeout) {
		return function(scope, element, attrs) {
			scope.$on('LastBrick', function(event) {
				var wall = new freewall("#freewall");
				wall.reset({
					selector: '.brick',
					animate: true,
					//fixSize: 0
					cellW: attrs.width || 160,
					cellH: attrs.height || 160,
					onResize: function() {
						if (attrs.direction === 'x') {
							wall.fitHeight($(window).height() - $('ion-view[nav-view="active"]').find('.header').height());
						} else {
							wall.fitWidth();
						}
					}
				});
				/*wall.container.find('.brick').each(function() {
					if (attrs.width) $(this).width(attrs.width);
					else $(this).width(Math.random() > 0.5 ? 380 : 190);
					
					console.log(attrs.width, $(this).width());
					if (attrs.height) $(this).height(attrs.height);
				});*/
				if (attrs.direction === 'x') {
					wall.fitHeight($(window).height() - $('ion-view[nav-view="active"]').find('.header').height());
				} else {
					wall.fitWidth();
				}
				$(window).trigger("resize");
				$rootScope.wall = wall;
			});

		};
	}

})();