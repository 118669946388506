(function () {
	'use strict';

	angular
		.module('App')
		.service('Language', Language);

	Language.$inject = [];
	function Language() {
		this.get = function() {
			return window.navigator.language || window.navigator.userLanguage; 
		};
	}
})();