(function () {
	'use strict';

	angular
		.module('App')
		.directive('scrollWatch', scrollWatch);

	scrollWatch.$inject = ['$rootScope'];
	function scrollWatch($rootScope) {

		$rootScope.slideHeader = false;
		$rootScope.slideHeaderPrevious = 0;

		return function(scope, elem, attr) {
			var start = 0;
			var threshold = 50;

			elem.bind('scroll', function(e) {
				if(e.detail.scrollTop - start > threshold) {
					$rootScope.slideHeader = true;
				} else {
					$rootScope.slideHeader = false;
				}
				if ($rootScope.slideHeaderPrevious > e.detail.scrollTop - start) {
					$rootScope.slideHeader = false;
				}
				$rootScope.slideHeaderPrevious = e.detail.scrollTop - start;
				$rootScope.$apply();
			});
		};
	}
})();