(function () {
	'use strict';

	angular
		.module('App')
		.controller('PanoramaController', PanoramaController);

	PanoramaController.$inject = ['$scope', 'Model'];
	function PanoramaController($scope, Model) {
		embedpano({swf:"krpano.swf", xml:"pano.xml", target:"pano"});
	}
})();