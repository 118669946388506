(function () {
	'use strict';

	angular
		.module('App')
		.factory('Destinations', Destinations);

	Destinations.$inject = ['$rootScope', '$q', '$http', '$fetchService'];
    function Destinations($rootScope, $q, $http, $fetchService) {
		var apiEndPoint = 'https://pce.api.wacan.com';
		
		return {
			getById: function(id, lang, forceRemote) {
				lang = lang || $rootScope.language;
				return $fetchService.fetch(apiEndPoint + '/destination/' + id, 'destination-' + id + '-' + lang + '.json', lang, forceRemote);
			},
			getSections: function(id, lang, forceRemote) {
				lang = lang || $rootScope.language;
				return $fetchService.fetch(apiEndPoint + '/destination/' + id + '/sections', 'destination-' + id + '-sections-' + lang + '.json', lang, forceRemote);
			},
			getAssets: function(id, lang, forceRemote) {
				lang = lang || $rootScope.language;
				return $fetchService.fetch(apiEndPoint + '/destination/' + id + '/assets', 'destination-' + id + '-assets-' + lang + '.json', lang, forceRemote);
			},
			getMessages: function(id, lang, forceRemote) {
				lang = lang || $rootScope.language;
				return $fetchService.fetch(apiEndPoint + '/destination/' + id + '/message', 'destination-' + id + '-messages-' + lang + '.json', lang, forceRemote);
			}
		};
    }

})();