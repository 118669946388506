// Ionic Starter App

// angular.module is a global place for creating, registering and retrieving Angular modules
// 'App' is the name of this angular module example (also set in a <body> attribute in index.html)
// the 2nd parameter is an array of 'requires'
angular.module('App', [
	'ionic',
	'ngCordova',
	'ionic-material',
	'ngQueue',
	'ImgCache',
	'pascalprecht.translate',
	'tmh.dynamicLocale',
	'ionic.contrib.drawer.vertical',
	'iso.directives',
	'ngAnimate',
	"com.2fdevs.videogular",
	"com.2fdevs.videogular.plugins.controls",
	"com.2fdevs.videogular.plugins.overlayplay",
	"com.2fdevs.videogular.plugins.poster"
])

.run([
	'$rootScope',
	'$state',
	'$ionicPopup',
	'$timeout',
	'$translate',
	'tmhDynamicLocale',
	'$ionicPlatform', 
	'$sqliteService',
	'$cordovaFile',
	'$cordovaNetwork',
	'$ionDrawerVerticalDelegate',
	'$ionicHistory',
	'Model',
	'ImgCache',
	'Modals',

	function($rootScope, $state,$ionicPopup ,$timeout, $translate, tmhDynamicLocale, $ionicPlatform, $sqliteService, $cordovaFile, $cordovaNetwork, $ionDrawerVerticalDelegate, $ionicHistory, Model, ImgCache, Modals) {
		$rootScope.language = localStorage.getItem('app_language');
		$rootScope.availableLanguages = ['fr', 'en'];
		$rootScope.defaultLanguage = 'en';
		
		if (!$rootScope.language) {
			var lang = (window.navigator.userLanguage || window.navigator.language).substr(0, 2);
			if ($rootScope.availableLanguages.indexOf(lang) === -1) {
				lang = $rootScope.defaultLanguage;
			}
			$rootScope.language = lang;
			localStorage.setItem('app_language', $rootScope.language);
		}
		
		// i18n
		$translate.use($rootScope.language);
		tmhDynamicLocale.set($rootScope.language);

		$(document).on('click touchstart', function(e) {
			e = e || window.event;
			var element = e.target || e.srcElement;

			if (element.tagName === 'A') {
				var href = element.href.trim().toLowerCase();
				// console.log(href);
				if (!href || !href.length) return false;

				if (href.substr(0, 4) === 'tel:' ||
					href.substr(0, 7) === 'mailto:' ||
					href.substr(0, 5) === 'maps:' ||
					href.substr(0, 4) === 'geo:') {
					if (href.substr(0, 4) === 'tel:') {
						window.open(element.href.replace(/ /g, ''), '_system');
					} else {
						window.open(element.href, '_system');
					}
				} else {
					window.open(element.href, '_blank', 'location=no,enableViewportScale=yes,closebuttoncaption=' + $translate.instant('INAPPBROWSER_CLOSE'));
				}
				return false;
			}
		});

		/* ROOT SCOPE */
		$rootScope.goHome = function() {
			var user = angular.fromJson(localStorage.user);
			Model.Destinations.getSections(user.destination.id).then(function(sections) {
				if (sections.length) {
					var position = 100;
					var item = null;
					angular.forEach(sections, function(value, key) {
						if(value.status == 'active' && value.position <= position){
							item = value;
							position = value.position;
						}
					}, this);

					switch (item.type) {
						case 'slider':
							$state.go('app.slider', {id: item.id, item: item});
							break;
						case '360':
							$state.go('app.panorama', {id: item.id, item: item});
							break;
						case 'places':
							$state.go('app.places', {id: item.id, item: item});
							break;
						case 'contacts':
							$state.go('app.contacts', {id: item.id, item: item});
							break;
						case 'photos':
							$state.go('app.gallery', {id: item.id, item: item});
							break;
						case 'documents':
							$state.go('app.documents', {id: item.id, item: item});
							break;
						default:
							alert('Type inconnu: ' + item.type);
					}


					$rootScope.isBackButtonVisible = false;
                    $ionicHistory.clearHistory();
				}
			});
		};

		$rootScope.safeApply = function(fn) {
		  var phase = this.$root.$$phase;
		  if(phase == '$apply' || phase == '$digest') {
		    if(fn && (typeof(fn) === 'function')) {
		      fn();
		    }
		  } else {
		    this.$apply(fn);
		  }
		};

		$rootScope.goBack = function() {
			// console.log('back');
			$('#backbutton').addClass('ng-hide');
			$rootScope.safeApply(function() {
				$rootScope.backButton = false;
			});
			
			$ionicHistory.goBack(-1);
			$ionDrawerVerticalDelegate.closeDrawer();
		};

		// Fonction de déconnexion
		$rootScope.logout = function(){
			// console.log('Logging out...')
			var exceptions = ['app_language'];
			var storage = localStorage;
			var keys = [];
			exceptions = [].concat(exceptions); //prevent undefined

			//get storage keys
			$.each(localStorage, function(key, val){
			keys.push(key);
			});

			//loop through keys
			for( i=0; i<keys.length; i++ ){
			var key = keys[i];
				var deleteItem = true;

				//check if key excluded
				for( j=0; j<exceptions.length; j++ ){
				  var exception = exceptions[j];
				  if( key == exception ){
				    deleteItem = false;
				  }
				}

				//delete key
				if( deleteItem ){
				  localStorage.removeItem(key);
				}
			}

			if(document.getElementById("api-style")) document.getElementById("api-style").remove();
			
			$ionicHistory.clearCache(['login','app','app.slider','app.gallery','app.contacts','app.places','app.documents','app.panorama']).then(function(){
				$timeout(function() {
					$ionicHistory.clearHistory();
					$state.reload();
				});
				$state.go("login");
			});
			
		};

		// Fonction pour lancer le rafraichissement
		$rootScope.refreshDatas = function(){
			// console.log('Refresh...');
			var exceptions = ['user','token','app_language'];
			var storage = localStorage;
			var keys = [];
			exceptions = [].concat(exceptions); //prevent undefined

			//get storage keys
			$.each(localStorage, function(key, val){
			keys.push(key);
			});

			//loop through keys
			for( i=0; i<keys.length; i++ ){
			var key = keys[i];
			var deleteItem = true;

			//check if key excluded
			for( j=0; j<exceptions.length; j++ ){
			  var exception = exceptions[j];
			  if( key == exception ){
			    deleteItem = false;
			  }
			}

			//delete key
			if( deleteItem ){
			  localStorage.removeItem(key);
			}
			}
            
            document.getElementById("api-style").remove();
			
			$ionicHistory.clearCache(['login','app','app.slider','app.gallery','app.contacts','app.places','app.documents','app.panorama']).then(function(){
				$timeout(function() {
					$ionicHistory.clearHistory();
				});
				$state.go("updater");
			});
		};

		$rootScope.goToSection = function(item, event) {
			var user = angular.fromJson(localStorage.user);
			Model.Destinations.getSections(user.destination.id).then(function(sections) {
				if (sections.length) {
					angular.forEach(sections, function(value, key) {
					  	if(value.position == 0){
					  		section = value;
					  	}
					}, this);
					if (section.id == item.id) {
						$rootScope.isBackButtonVisible = false;
						$ionicHistory.clearHistory();
					} else {
						$rootScope.isBackButtonVisible = true;
					}
				}
				
				$ionDrawerVerticalDelegate.closeDrawer();
				switch (item.type) {
					case 'slider':
						$state.go('app.slider', {id: item.id, item: item});
						break;
					case '360':
						$state.go('app.panorama', {id: item.id, item: item});
						break;
					case 'places':
						$state.go('app.places', {id: item.id, item: item});
						break;
					case 'contacts':
						$state.go('app.contacts', {id: item.id, item: item});
						break;
					case 'photos':
						$state.go('app.gallery', {id: item.id, item: item});
						break;
					case 'documents':
						$state.go('app.documents', {id: item.id, item: item});
						break;
					default:
						alert('Type inconnu: ' + item.type);
				}
			});
		};


		$rootScope.$on('$stateChangeStart', function(evt, to, params) {
			$ionicPlatform.ready(function() { 
				if (ionic.Platform.platforms.includes('browser','win32')){
					if(typeof params.item !== 'undefined' && params.item == null){
				       	var user = angular.fromJson(localStorage.user);
				       	if(user){
							Model.Destinations.getSections(user.destination.id).then(function(sections) {
								var findID = false;
								angular.forEach(sections, function(value, key) {
								  	if(params.id == value.id){
								  		findID = true;
								  		$state.go(to.name, {id: value.id, item: value});
								  		return false;
								  	}
								}, this);
								if(!findID){
									$rootScope.goHome();
									return false;
								}
							});
						}else{
							if($state.current.name != 'login'){
								$rootScope.logout();
								$state.go("login");
								return false;
							}
						}
					}
				}
			});	
	    });
		
		$ionicPlatform.ready(function() {
			if (!ionic.Platform.platforms.includes('browser','win32')){
				if(window.cordova && window.cordova.plugins.Keyboard) {
					// Hide the accessory bar by default (remove this to show the accessory bar above the keyboard
					// for form inputs)
					cordova.plugins.Keyboard.hideKeyboardAccessoryBar(true);
					// Don't remove this line unless you know what you are doing. It stops the viewport
					// from snapping when text inputs are focused. Ionic handles this internally for
					// a much nicer keyboard experience.
					cordova.plugins.Keyboard.disableScroll(true);

					cordova.getAppVersion.getVersionNumber().then(function (version) {
					    $rootScope.version = version;
					});

					console.log($rootScope);

				}
				if(window.StatusBar) {
					// console.log('statusbar',StatusBar);
					StatusBar.hide();
				}
				if (ionic.Platform.isAndroid()) {
			        window.addEventListener("native.hidekeyboard", function () { 
			        StatusBar.hide();
		           });
		     	}

				// Initialize ImgCache
				/*ImgCache.options.cordovaFilesystemRoot = cordova.file.dataDirectory;*/
				ImgCache.$init();
			}else{
				var ua = ionic.Platform.ua,
				 	expr = "Edge"; 

				if(ua.indexOf(expr) !== -1){
					if(!getCookie('EdgeExpire')){
						$ionicPopup.alert({
						   cssClass: 'my-custom-popup',
					       title: 'Performance',
					       template: 'Nous avons optimisé l\'affichage de votre web application sur les navigateurs Chrome et Firefox.\n Nous vous conseillons de changer de navigateur.'
				     	}).then(function(res) {
					       	if(!getCookie('EdgeExpire')){
					       		setCookie('EdgeExpire', 1 , 1);
					       	}
				     	});
				    }
				}
				
			}
	    });
    }
])
.config([
	'$stateProvider',
	'$urlRouterProvider',
	'$ionicConfigProvider',
	'$compileProvider',
	'ImgCacheProvider',
	'tmhDynamicLocaleProvider',
	'$translateProvider',

	function ($stateProvider, $urlRouterProvider, $ionicConfigProvider, $compileProvider, ImgCacheProvider, tmhDynamicLocaleProvider, $translateProvider) {
		// Set ImgCache options
		ImgCacheProvider.setOptions({
			debug: false,
			usePersistentCache: true,
			chromeQuota: 500*1024*1024
		});
		ImgCacheProvider.manualInit = true;

		$compileProvider.imgSrcSanitizationWhitelist(/^\s*(https?|ftp|file|blob|cdvfile|content|ms-appx|x-wmapp0|filesystem):|data:image\/|img\//);
		$compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|file|ghttps?|ms-appx|x-wmapp0|maps|comgooglemaps|geo|mailto|tel|filesystem):/);

		$ionicConfigProvider.scrolling.jsScrolling(ionic.Platform.isIOS());
		//$ionicConfigProvider.views.maxCache(0);

		tmhDynamicLocaleProvider.localeLocationPattern('lib/angular-i18n/angular-locale_{{locale}}.js');

		$translateProvider.useStaticFilesLoader({
			prefix: 'translations/',
			suffix: '.json'
		});

		$stateProvider
			.state('login', {
				url: "/login",
				cache: false,
				templateUrl: "templates/login.html",
				controller: 'LoginController'
			})
			.state('updater', {
				url: "/updater",
				cache: false,
				templateUrl: "templates/updater.html",
				controller: 'UpdaterController'
			})
			.state('app', {
				url: '/app',
				abstract: true,
				controller: 'AppController',
				templateUrl: function() {
				    if (ionic.Platform.platforms.includes('browser','win32')) {
				        return  'templates/browser/menu.html';
				    }
				    	return 'templates/menu.html';
				}
			})
			.state('app.slider', {
				url: "/slider/:id",
				params: {
					id: null,
					item: null,
					back: false
				},
				views: {
					viewContent: {
						templateUrl: function() {
					    if (ionic.Platform.platforms.includes('browser','win32')) {
					        return  "templates/browser/slider.html";
					    }
					    	return "templates/slider.html";
  						},
						controller: 'SliderController'
					}
				}
			})
			.state('app.gallery', {
				url: "/gallery/:id",
				params: {
					id: null,
					item: null
				},
				views: {
					viewContent: {
						templateUrl: function() {
					    if (ionic.Platform.platforms.includes('browser','win32')) {
					        return  "templates/browser/gallery.html";
					    }
					    	return "templates/gallery.html";
  						},
						controller: 'GalleryController'
					}
				}
			})
			.state('app.places', {
				url: "/places/:id",
				params: {
					id: null,
					item: null
				},
				views: {
					viewContent: {
						templateUrl: function() {
					    if (ionic.Platform.platforms.includes('browser','win32')) {
					        return  "templates/browser/places.html";
					    }
					    	return "templates/places.html";
  						},
						controller: 'PlacesController'
					}
				}
			})
			.state('app.contacts', {
				url: "/contacts/:id",
				params: {
					id: null,
					item: null
				},
				views: {
					viewContent: {
						templateUrl: function() {
					    if (ionic.Platform.platforms.includes('browser','win32')) {
					        return  "templates/browser/contacts.html";
					    }
					    	return "templates/contacts.html";
  						},
						controller: 'ContactsController'
					}
				}
			})
			.state('app.documents', {
				url: "/documents/:id",
				params: {
					id: null,
					item: null
				},
				views: {
					viewContent: {
						templateUrl: function() {
					    if (ionic.Platform.platforms.includes('browser','win32')) {
					        return  "templates/browser/documents.html";
					    }
					    	return "templates/documents.html";
  						},
						controller: 'DocumentsController'
					}
				}
			})
			.state('app.panorama', {
				url: "/panorama/:id",
				params: {
					id: null,
					item: null
				},
				views: {
					viewContent: {
						templateUrl: "templates/panorama.html",
						controller: 'PanoramaController'
					}
				}
			});


		$urlRouterProvider.otherwise(function ($injector, $location) {
			var $state = $injector.get("$state");
			var Model = $injector.get("Model");
			var $rootScope = $injector.get("$rootScope");
			var $timeout = $injector.get("$timeout");
			var $ionicHistory = $injector.get("$ionicHistory");
			$ionicHistory = $injector.get("$ionicHistory");
			ionic.Platform.ready(function(){
				if(window.StatusBar) {
					// console.log('statusbar',StatusBar);
					StatusBar.hide();
				}
				// Vérifier ici la présence de l'user en localStore et rediriger vers l'app si l'utilisateur est present
				// Il faut aussi ajouter une info pour le logout
				var user = angular.fromJson(localStorage.user);
				if (!user) {
					// Si l'utilisateur n'est pas loggué, alors on le renvoi sur le login
					$state.go("login");
					navigator.splashscreen.hide();
				} else {
					// Si l'utilisateur est déjà loggué depuis une ancienne session, alors on le redirige vers l'application
					$rootScope.goHome();

					$timeout(function() {
						$ionicHistory.clearHistory();
					});

					if(navigator.splashscreen != undefined)
					    navigator.splashscreen.hide();
					
				}
			});
			
		});
	}
]);

//COOKIE FUNCTION
function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return false;
}