(function () {
	'use strict';

	angular
		.module('App')
		.factory('Model', Model);

	Model.$inject = ['$q', 'Destinations', 'Users'];
	function Model($q, Destinations, Users) {

		return {
			Destinations: Destinations,
			Users: Users
		};
	}
})();