(function () {
    'use strict';

    angular
        .module('App')
        .controller('AppController', AppController);

    AppController.$inject = ['$scope', '$rootScope', '$state', '$http', '$timeout', '$document', '$ionicScrollDelegate', '$q', '$translate', 'Model', 'Modals','$ionDrawerVerticalDelegate'];
    function AppController($scope, $rootScope, $state, $http, $timeout, $document, $ionicScrollDelegate, $q, $translate, Model, Modals,$ionDrawerVerticalDelegate) {
        $http.defaults.headers.common['X-Auth-Token'] = angular.fromJson(localStorage.token).value;
		$scope.isDisabled = false;

		// Menu items
		$scope.sections = [];
		function getSections() {
			$scope.sections.length = 0; // clear sections
			
			var user = angular.fromJson(localStorage.user);
			Model.Destinations.getSections(user.destination.id).then(function(sections) {
				var now = new Date();
				sections.forEach(function(section) {
					if (section.dateFrom && new Date(section.dateFrom) > now) return; // not started yet
					if (section.dateTo && new Date(section.dateTo) < now) return; // ended
					$scope.sections.push(section);
				});
			});
		}
		
    var getCachedAsset = function(url, cb) {
        var deferred = $q.defer();

        if(ionic.Platform.platforms.includes('browser','win32')){
        	if (typeof cb === 'function') cb(url);
        	deferred.resolve(url);
        	return  deferred.promise;
        }

        ImgCache.$promise.then(function() {
          ImgCache.isCached(url, function(path, success) {
            if (success) {
              ImgCache.getCachedFileURL(url, function(src, dest) {
                if (typeof cb === 'function') cb(dest);

                deferred.resolve(dest);
              });
            } else {
            	console.log('Cache error', url);
            	deferred.resolve(url);
            }
          });
        });
        return deferred.promise;
      };
      
      var applyGenericStyle = function(property, exclude) {
        exclude = exclude || [];
        var excludedPoperties = ['asset_fr', 'asset_en'].concat(exclude);
        var css = '';
        for (var k in property) {
          if (excludedPoperties.indexOf(k) > -1) continue;
          if (typeof property[k] === 'object') continue;
          if (['asset'].indexOf(k) > -1) {
            css += 'background-image: url("' + property[k] + '") !important;';
          } else {
            css += k.replace(/_/g, '-') + ':' + property[k] + ' !important;';
          }
        }
        return css;
      };

		// Store destination in $rootScope
		var user = angular.fromJson(localStorage.user);
		Model.Destinations.getById(user.destination.id).then(function(data) {
			var customs;

			/*************************************/
		      	var customs_logo = function(url_data) { getCachedAsset(url_data, function(url){ customs.logo.asset = url; }); };

				var customs_menu_home = function(url_data) { getCachedAsset(url_data, function(url){ customs.menu.home.asset = url; }); };

				var customs_menu_back = function(url_data) { getCachedAsset(url_data, function(url){ customs.menu.back.asset = url; }); };

				var customs_menu_logout = function(url_data) { getCachedAsset(url_data, function(url){ customs.menu.logout.asset = url; }); };

				var customs_menu_refresh = function(url_data) { getCachedAsset(url_data, function(url){ customs.menu.refresh.asset = url; }); };

				var customs_menu_menu = function(url_data) { getCachedAsset(url_data, function(url){ customs.menu.menu.asset = url; }); };

				var customs_menu_langue_fr = function(url_data) { getCachedAsset(url_data, function(url){ customs.menu.language.asset_fr = url; }); };

				var customs_menu_langue_en = function(url_data) { getCachedAsset(url_data, function(url){ customs.menu.language.asset_en = url; }); };

				var customs_fonts_title  = function(url_data) { getCachedAsset(url_data, function(url){ customs.fonts.title.asset = url; }); };
				
				var customs_fonts_text = function(url_data) { getCachedAsset(url_data, function(url){ customs.fonts.text.asset = url; }); };

				var customs_slider_separator = function(url_data) { getCachedAsset(url_data, function(url){ customs.slider.separator = url; }); };

				var customs_place_separator = function(url_data) { getCachedAsset(url_data, function(url){ customs.place.separator = url; }); };

				var customs_document_separator = function(url_data) { getCachedAsset(url_data, function(url){ customs.document.separator = url; }); };
													
				var customs_contact_separator = function(url_data) { getCachedAsset(url_data, function(url){ customs.contact.separator = url; }); };

				var customs_link = function(url_data) {
					getCachedAsset(url_data, function(url){
						var ss = document.createElement('link');
						ss.type = 'text/css';
						ss.rel = 'stylesheet';
						ss.href = url;
						document.head.appendChild(ss);
					});
				}

		    /*************************************/
			if (data) {
				$rootScope.destination = data;
				// Convert customs (json string) to object
				if (typeof $rootScope.destination.customs === 'string') {
					
					try {
						customs = angular.fromJson($rootScope.destination.customs);
					} catch (e) { customs = null; }
					if (customs) {
						if (!customs.menu) {
							customs.menu = {};
						}
						if (!customs.menu.position) {
							customs.menu.position = 'left';
						
						}
						$rootScope.destination.customs = customs;

						//$rootScope.destination.customs.menu.position = "bottom"; //TODO: REMOVE THIS
						
						var menuPos = customs.menu.position;
						if (menuPos) {
							var ionContent = $ionicScrollDelegate.$getByHandle('main-menu-content');
							if (ionContent) {
								ionContent.getScrollView().options.scrollingX = !(menuPos === 'left' || menuPos === 'right'); // top/bottom = horizontal scroll
								ionContent.getScrollView().options.scrollingY = (menuPos === 'left' || menuPos === 'right'); // left/right = vertical scroll
							}
						}
						
						// Get destination assets
						Model.Destinations.getAssets(user.destination.id).then(function(data) {
							if (data) {
								var promises = [];
								for (var i=0; i<data.length; i++) {
									// Logo
									if (customs.logo && data[i].id == customs.logo.asset) {
										promises.push(customs_logo(data[i].url));
									}
									
									// Menu
									/*if (customs.menu) {

										if (customs.menu.home && data[i].id == customs.menu.home.asset) {
											promises.push(customs_menu_home(data[i].url));
										}
										if (customs.menu.back && data[i].id == customs.menu.back.asset) {
											promises.push(customs_menu_back(data[i].url));
										}
										// ---
										if (customs.menu.logout && data[i].id == customs.menu.logout.asset) {
											promises.push(customs_menu_logout(data[i].url));
										}
										// ---
										if (customs.menu.refresh && data[i].id == customs.menu.refresh.asset) {
											promises.push(customs_menu_refresh(data[i].url));
										}

										if (customs.menu.menu && data[i].id == customs.menu.menu.asset) {
											promises.push(customs_menu_menu(data[i].url));
										}
										if (customs.menu.language && data[i].id == customs.menu.language.asset_fr) {
											promises.push(customs_menu_langue_fr(data[i].url));
										}
										if (customs.menu.language && data[i].id == customs.menu.language.asset_en) {
											promises.push(customs_menu_langue_en(data[i].url));
										}
									}*/
									
									// Fonts
									if (customs.fonts) {
										if (data[i].id == customs.fonts.title.asset) {
											promises.push(customs_fonts_title(data[i].url));
										}
										if (data[i].id == customs.fonts.text.asset) {
											promises.push(customs_fonts_text(data[i].url));
										}
									}
									
									// Slider
									if (customs.slider) {
										if (data[i].id == customs.slider.separator) {
											promises.push(customs_slider_separator(data[i].url));
										}
									}
									
									// Place
									if (customs.place) {
										if (data[i].id == customs.place.separator) {
											promises.push(customs_place_separator(data[i].url));
										}
									}
									
									// Document
									if (customs.document) {
										if (data[i].id == customs.document.separator) {
											promises.push(customs_document_separator(data[i].url));
										}
									}
									
									// Contact
									if (customs.contact) {
										if (data[i].id == customs.contact.separator) {
											promises.push(customs_contact_separator(data[i].url));
										}
									}
									
									// Custom CSS
									if (customs.custom_css_file) {
										if (data[i].id == customs.custom_css_file) {
											promises.push(customs_link(data[i].url));
										}
									}
								}

								// Generate CSS
								$q.all(promises).then(function() {
									var css = "";
									if (customs.fonts) { 
										css +="/* Fonts */"+
										"@font-face { "+
											"font-family: '" + customs.fonts.title.font_family + "'; "+
											"src: url('" + customs.fonts.title.asset + "') format('truetype'); "+
										"} "+
										"@font-face { "+
											"font-family: '" + customs.fonts.text.font_family + "'; "+
											"src: url('" + customs.fonts.text.asset + "') format('truetype'); "+
										"} "+
										"h1, .custom-title { "+
											"font-family: '" + customs.fonts.title.font_family + "', 'Ubuntu', Arial !important; "+
											"font-size: " + customs.fonts.title.font_size + " !important; "+
											"font-weight: " + customs.fonts.title.font_weight + " !important; "+
											"font-style: " + customs.fonts.title.font_style + " !important; "+
										"} "+
										".custom-text { "+
											"font-family: '" + customs.fonts.text.font_family + "', 'Ubuntu', Arial !important; "+
											"font-size: " + customs.fonts.text.font_size + " !important; "+
											"font-weight: " + customs.fonts.text.font_weight + " !important; "+
											"font-style: " + customs.fonts.text.font_style + " !important; "+
										"}";
									}

									if (customs.logo) {
										
										css += "/* Logo */ "+
										".logo {" + applyGenericStyle(customs.logo) + "} ";
									}
									if (customs.menu && customs.menu.element) {
										css += 
										".section {" + applyGenericStyle(customs.menu.element, ['title']) + "} "+
										".section .title {" + applyGenericStyle(customs.menu.element.title, ['position']) + "} ";
									}
									if (customs.slider) {
										
										css += "/* Slider */ "+
										".sliderView .wave { mask-image: url(" + customs.slider.separator + ") !important; background-color: " + customs.slider.separator_color + " !important; } "+
										".sliderView .block-infos { " + applyGenericStyle(customs.slider, ['separator']) + "} "+
										".sliderView .title { " + applyGenericStyle(customs.slider.title) + "} "+
										".sliderView .text { " + applyGenericStyle(customs.slider.text) + "} ";
										
										if (customs.slider.button) {
											css += ".sliderView .icon-button { " + applyGenericStyle(customs.slider.button.active) + "} "+
											".sliderView .icon-button.active { " + applyGenericStyle(customs.slider.button) + "} "+
											".sliderView .icon-button .icon { " + applyGenericStyle(customs.slider.button.active.icon) + "} "+
											".sliderView .icon-button.active .icon { " + applyGenericStyle(customs.slider.button.icon) + "} ";
											
										}
									}

									if (customs.place) {
										
										css += "/* Place */ "+
										".placesView .wave { mask-image: url(" + customs.place.separator + ") !important; background-color: " + customs.place.separator_color + " !important; } "+
										".placesView { " + applyGenericStyle(customs.place, ['separator']) + "} "+
										".placesView .header .title { " + applyGenericStyle(customs.place.title) + "} "+
										".placesView .filter { " + applyGenericStyle(customs.place.filter) + "} "+
										".placesView .filter.active { " + applyGenericStyle(customs.place.filter.active) + "} "+
										".placesView .brick { " + applyGenericStyle(customs.place.element) + "} "+
										".placesView .brick .infos { " + applyGenericStyle(customs.place.element.text, ['position']) + "} "+
										".placesView .brick .title { " + applyGenericStyle(customs.place.element.title) + "} ";
										
									}
									if (customs.document) {
										
										css += "/* Document */ "+
										".documentsView .wave { mask-image: url(" + customs.document.separator + ") !important; background-color: " + customs.document.separator_color + " !important; } "+
										".documentsView { " + applyGenericStyle(customs.document, ['separator']) + "} "+
										".documentsView .header .title { " + applyGenericStyle(customs.document.title) + "} "+
										".documentsView .filter { " + applyGenericStyle(customs.document.filter) + "} "+
										".documentsView .filter.active { " + applyGenericStyle(customs.document.filter.active) + "} "+
										".documentsView .brick { " + applyGenericStyle(customs.document.element) + "} "+
										".documentsView .brick .infos { " + applyGenericStyle(customs.document.element.text, ['position']) + "} "+
										".documentsView .brick .title { " + applyGenericStyle(customs.document.element.title) + "} ";
										
									}
									if (customs.contact) {
										
										css += "/* Contact */ "+
										".contactsView .wave { mask-image: url(" + customs.contact.separator + ") !important; background-color: " + customs.contact.separator_color + " !important; } "+
										".contactsView { " + applyGenericStyle(customs.contact, ['separator']) + "} "+
										".contactsView .header .title { " + applyGenericStyle(customs.contact.title) + "} "+
										".contactsView .filter { " + applyGenericStyle(customs.contact.filter) + "} "+
										".contactsView .filter.active { " + applyGenericStyle(customs.contact.filter.active) + "} "+
										".contactsView .brick { " + applyGenericStyle(customs.contact.element) + "} "+
										".contactsView .brick .infos { " + applyGenericStyle(customs.contact.element.text, ['position']) + "} "+
										".contactsView .brick .title { " + applyGenericStyle(customs.contact.element.title) + "} "+
										".contactsView .brick .email { " + applyGenericStyle(customs.contact.element.email) + "} "+
										".contactsView .brick .phone { " + applyGenericStyle(customs.contact.element.phone) + "} ";
										
									}
									if (customs.photo) {
										
										css += "/* Photo Gallery */ "+
										".galleryView .wave { mask-image: url(" + customs.photo.separator + ") !important; background-color: " + customs.photo.separator_color + " !important; } "+
										".galleryView { " + applyGenericStyle(customs.photo, ['separator']) + "} "+
										".galleryView .header .title { " + applyGenericStyle(customs.photo.title) + "} "+
										".galleryView .filter { " + applyGenericStyle(customs.photo.filter) + "} "+
										".galleryView .filter.active { " + applyGenericStyle(customs.photo.filter.active) + "} ";
										
									}
									
									// Generate prefixed css
									autoprefixer.process(css).then(function(res) {
										// Inject it to document
										var newStyle = document.createElement('style');
										newStyle.id = 'api-style';
										newStyle.appendChild(document.createTextNode(res.css));
										document.head.appendChild(newStyle);
									}, function() {
										// Error while autoprefixing (parse error?)
										// Try to inject unprefixed version
										var newStyle = document.createElement('style');
										newStyle.appendChild(document.createTextNode(css));
										document.head.appendChild(newStyle);
									});
								});
							}
						});
					}
				}	
			}
		}, function(err) {
			$rootScope.destination = null;
			console.log(err);
		});
		
		// Get sections
		getSections();
		
		$scope.changeLanguage = function() {

			$scope.isDisabled = true;

			if ($rootScope.language === 'fr') {
				$rootScope.language = 'en';
			} else {
				$rootScope.language = 'fr';
			}

			$translate.use($rootScope.language).then(function(){
				getSections();
				// console.log($state);
				$state.reload();
				getDevis().then(function(messages) {
					$scope.isDisabled = false;
					if (!messages.length) return;
					updateDevis(messages[0]);

				});
			});
			
		};
		
		// Devis
		$scope.download = function(devis) {
			if (devis && devis.file) {
				window.open(devis.file.file.url, '_blank',"location=no,toolbar=yes,closebuttoncaption=X,enableViewportScale=yes,presentationstyle=pagesheet");
			}
		};

		function getDevis() {
			var deferred = $q.defer();
			var user = angular.fromJson(localStorage.user);
			if (user) {
				Model.Users.getMessages(user.id).then(function(messages) {
					deferred.resolve(messages);
				});
			}
			return deferred.promise;
		}

		function updateDevis(message) {

			var user = angular.fromJson(localStorage.user);
			
			$scope.devis = {
				title: message.title,
				message: message.content,
				contact: user.contact ? {
					name: user.contact.firstname + ' ' + user.contact.lastname,
					occupation: user.contact.description,
					photo: user.contact.photo.url,
					phone: user.contact.phone,
					email: user.contact.email
				} : null
			};

			Model.Users.getAssets(user.id).then(function(assets) {
				if (!assets.length) return;
				assets = assets.filter(function(a){ return a.status != 'deleted'; });
				var asset = assets[0];
				$scope.devis.files = [];
				assets.forEach(function(a){
					if(a.name == ""){
						a.name = a.file.url.split('/').pop();
					}
					$scope.devis.files.push({
						length: a.length,
						name: a.name.split('.')[0],
						type: a.file.url.split('.').pop(),
						lenghtType: a.file.url.split('.').pop().length,
						file: a
					});
				});
				$scope.devis.length = assets.length;
				$scope.devis.file = asset;
			});
		}

		$scope.toggleDrawer = function(handle) {
			$ionDrawerVerticalDelegate.$getByHandle(handle).toggleDrawer();

			if(handle == 'menu'){
				$ionDrawerVerticalDelegate.$getByHandle('modal').closeDrawer();
			}

			if(handle == 'modal'){
				if($ionDrawerVerticalDelegate.$getByHandle('menu').getState() == 'opened')
				   $ionDrawerVerticalDelegate.$getByHandle('menu').closeDrawer();
			}
		};

		$("ion-nav-view[name='viewContent']").on("click", function () {

	        if($ionDrawerVerticalDelegate.$getByHandle('menu').getState() == 'opened'){
	        	$ionDrawerVerticalDelegate.$getByHandle('menu').closeDrawer();
	        }

	        if($ionDrawerVerticalDelegate.$getByHandle('modal').getState() == 'opened'){
	        	$ionDrawerVerticalDelegate.$getByHandle('modal').closeDrawer();
	        }
	      
	    });

		$scope.drawerIs = function(handle,state) {
			return $ionDrawerVerticalDelegate.$getByHandle(handle).getState() == state;
		};

		$scope.closeModal = function() {
			Modals.closeModal();
		};

		$scope.$watch(function () {
		    return $ionDrawerVerticalDelegate.$getByHandle('menu').getState();
		},
		function (isOpen) {
		    if (isOpen == 'opened'){
		      $ionDrawerVerticalDelegate.$getByHandle('modal').closeDrawer();
		    }
		});

		getDevis().then(function(messages) {
			if (!messages.length) return;
			
			updateDevis(messages[0]);
			
			$scope.toggleDrawer('modal');

		});
	}
})();
