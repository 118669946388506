(function () {
	'use strict';

	angular
		.module('App')
		.service('$fetchService', $fetchService);

	$fetchService.$inject = ['$q', '$http', '$cordovaFile', '$cordovaNetwork'];
	function $fetchService($q, $http, $cordovaFile, $cordovaNetwork) {

		var self = this;

		self.fetch = function(url, fileName, lang, forceRemote) {
			var deferred = $q.defer();
			if (forceRemote) {
				if ((window.cordova && $cordovaNetwork.isOnline()) || window.navigator.onLine) {
					$http.get(url, {params: {language: lang}})
					.success(function(res) {
						if (res.error) {
							deferred.reject(res.error);
							return false;
						}
						// console.log("8");
						// Cache result
						if (window.cordova) {
							$cordovaFile.writeFile(cordova.file.dataDirectory, fileName, angular.toJson(res), true);
						} else {
							localStorage.setItem(fileName, angular.toJson(res));
						}
						deferred.resolve(res);
					})
					.error(function(err) {
						deferred.reject(err);
					});
				} else {
					var cached = self.getCache(fileName);
					if (cached) deferred.resolve(cached);
					else deferred.reject();
				}
			} else {
				var cached = self.getCache(fileName);
				if (cached) deferred.resolve(cached);
				else deferred.reject();
			}
			return deferred.promise;
		};
		
		self.getCache = function(fileName) {
			var deferred = $q.defer();
			if (window.cordova) {
				// console.log(cordova);
				$cordovaFile.readAsText(cordova.file.dataDirectory, fileName).then(function(result) {
					var data;
					try {
						data = angular.fromJson(result);
					} catch (e) { data = null; }
					if (data) {
						deferred.resolve(data);
					} else {
						deferred.reject();
					}
				}, function() {
					deferred.reject();
				});
			} else {
				var data;
				try {
					data = angular.fromJson(localStorage.getItem(fileName));
				} catch (e) { data = null; }
				if (data) {
					deferred.resolve(data);
				} else {
					deferred.reject();
				}
			}
			return deferred.promise;
		};
	}
})();