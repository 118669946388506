(function () {
	'use strict';

	angular
		.module('App')
		.controller('ContactsController', ContactsController);

	ContactsController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', '$timeout', '$ionicScrollDelegate', 'Modals', 'Model'];
	function ContactsController($scope, $rootScope, $state, $stateParams, $timeout, $ionicScrollDelegate, Modals, Model) {
		// Sliders
		// Options par défaut pour le slider
		$scope.sliders = {};
		$scope.pageSliderOptions = {
			loop: false,
			pagination: false,
			iOSEdgeSwipeDetection: true,
			initialSlide: $scope.activeSlide,
			onSlideChangeStart: function(swiper) {
				$scope.activeSlide = swiper.activeIndex;
			}
		};
		
		// Scroll to top
		$scope.scrollToTop = function() {
			$ionicScrollDelegate.scrollTop();
		};
		
		// Filters
		$scope.filters = [];
		
		// Load data
		$scope.items = [];
		$scope.language = null;
		$scope.$on("$ionicView.enter", function(event, data) {
			if (!$stateParams.item) {
				$rootScope.goHome();
				return;
			}
			
			var user = angular.fromJson(localStorage.user);
			Model.Destinations.getSections(user.destination.id).then(function(sections) {
				sections = sections.filter(function(s){ return s.status != 'deleted'; });
				var found = false;
				for (var i=0; i<sections.length; i++) {
					if (sections[i].id == data.stateParams.id) {
						loadItem(sections[i]);
						found = true;
						break;
					}
				}
				if (!found && data.stateParams.item) {
					loadItem(data.stateParams.item);
				}
				
			});
		});
		
		function loadItem(item) {
			$scope.title = item.name;
			$scope.lineLength = item.lineLength;
			
			// Filters
			$scope.filters.length = 0;
			item.contactCategories = item.contactCategories.filter(function(o){ return (o.status != 'deleted'); });
			item.contactCategories.forEach(function(category) {
				$scope.filters.push(category);
			});
		
			// Items
			if ($scope.language != $rootScope.language) {
				$scope.language = $rootScope.language;

				$timeout(function() {
					item.contacts = item.contacts.filter(function(o){ return (o.status != 'deleted'); });
					$scope.items = item.contacts.slice();
					heightMasonry();
				});

				
			}

			// Reset filters
			$scope.$broadcast('iso-option', {filter:'*'});
		}

		function heightMasonry(){
			$scope.viewHeight = window.innerHeight - $("ion-view[view-title='"+$scope.title+"'] .header").height();
			$scope.$apply();
			// console.log($("ion-view[view-title='"+$scope.title+"'] .isotope-view .header"));
			if($scope.viewHeight == window.innerHeight){
				$timeout(function() {
					heightMasonry();
				});
			}
		}
		
		$scope.selectItem = function(item, index, $event) {
			if (item.sliders.length) {
				$state.go('app.slider', {id: item.id, item: item});
			}
		};
	}
})();