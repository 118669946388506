(function () {
	'use strict';

	angular
		.module('App')
		.controller('UpdaterController', UpdaterController);

	UpdaterController.$inject = ['$scope', '$rootScope', '$state', '$timeout', '$http', '$q', '$translate', '$ionicHistory', 'Model'];
	function UpdaterController($scope, $rootScope, $state, $timeout, $http, $q, $translate, $ionicHistory, Model) {
		$http.defaults.headers.common['X-Auth-Token'] = angular.fromJson(localStorage.token).value;
		
		var user = angular.fromJson(localStorage.user);
		if (!user) {
			$state.go('login');
			return;
		}
		var userId = user.id;
		
		var updater = {
			steps: []
		};
		
		var customs;
		
		// Destination
		if (user.destination) {
			var destinationId = user.destination.id;
			$rootScope.availableLanguages.forEach(function(lang) {
				updater.steps.push({
					title: $translate.instant('UPDATER_STEP_1'),
					start: function() {
						return Model.Destinations.getById(destinationId, lang, true);
					}
				});
			});
			$rootScope.availableLanguages.forEach(function(lang) {
				updater.steps.push({
					title: $translate.instant('UPDATER_STEP_2'),
					start: function() {
						return Model.Destinations.getSections(destinationId, lang, true);
					}
				});
			});
			$rootScope.availableLanguages.forEach(function(lang) {
				updater.steps.push({
					title: $translate.instant('UPDATER_STEP_3'),
					start: function() {
						var promise = Model.Destinations.getAssets(destinationId, lang, true);
						promise.then(function(data) {
							// Download assets
							var uniqueAssets = {};
							var cachedAssets;
							try {
								cachedAssets = angular.fromJson(localStorage.getItem('cachedAssets'));
							} catch (e) {}
							if (!cachedAssets) cachedAssets = [];
							
							uniqueAssets = {};
							data.forEach(function(asset) {
								if (uniqueAssets[asset.url]) {
									if (new Date(asset.updatedAt) > new Date(uniqueAssets[asset.url].updatedAt)) {
										uniqueAssets[asset.url] = asset;
									}
								} else {
									uniqueAssets[asset.url] = asset;
								}
							});
							
							var updateQueue = [];
							for (var url in uniqueAssets) {
								var asset = uniqueAssets[url];
								var found = false;
								
								for (var i = 0; i < cachedAssets.length; i++) {
									if (cachedAssets[i].url === url) {
										// Same url as cached asset, compare date
										found = true;
										if (new Date(asset.updatedAt) > new Date(cachedAssets[i].updatedAt)) {
											updateQueue.push(asset);
										}
									}
								}
								
								if (!found) updateQueue.push(asset);
							}
							
							updater.steps.splice(currentStepIndex, 0, {
								title: $translate.instant('UPDATER_FINALIZE'),
								start: function() {
									var deferred = $q.defer();
									$timeout(function() {
										localStorage.setItem('cachedAssets', angular.toJson(cachedAssets));
										deferred.resolve();
									});
									return deferred.promise;
								}
							});
							
							updateQueue.forEach(function(asset, i) {
								updater.steps.splice(currentStepIndex, 0, {
									title: $translate.instant('UPDATER_DOWNLOADING') + ' ' + (updateQueue.length - i) + '/' + updateQueue.length,
									parallel: true,
									start: function() {
										var deferred = $q.defer();
										if(!ionic.Platform.platforms.includes('browser','win32')){
											ImgCache.cacheFile(asset.url, function() {
												// Success
												var found = false;
												for (var i = 0; i < cachedAssets.length; i++) {
													if (cachedAssets[i].url === asset.url) {
														cachedAssets[i] = asset;
														found = true;
														break;
													}
												}
												if (!found) cachedAssets.push(asset);
												$timeout(function() {
													deferred.resolve();
												});
											}, function() {
												// Error
												deferred.reject();
											}, function (progressEvent) {
												// Progress
												var percentage = Math.floor(progressEvent.loaded / progressEvent.total * 100);
												$timeout(function() {
													$scope.stepSubtitle = percentage + '%';
												});
											});
										}else{
											cachedAssets.push(asset);
											$timeout(function() {
												deferred.resolve();
											});
										}
										return deferred.promise;
									}
								});
							});
						});
						
						return promise;
					}
				});
			});
		}
		
		// User
		$rootScope.availableLanguages.forEach(function(lang) {
			// console.log("UPDATE 4");
			updater.steps.push({
				title: $translate.instant('UPDATER_STEP_4'),
				start: function() {
					return Model.Users.getMessages(userId, lang, true);
				}
			});
		});
		$rootScope.availableLanguages.forEach(function(lang) {
			// console.log("UPDATE 5");
			updater.steps.push({
				title: $translate.instant('UPDATER_STEP_5'),
				start: function() {
					return Model.Users.getAssets(userId, lang, true);
				}
			});
		});
		
		var currentStepIndex = 0;
		
		var parallelCount = 0;
		var maxParallel = 10;
		$scope.errors = [];
		
		function nextStep() {
			currentStepIndex++;
			
			var step = updater.steps[currentStepIndex - 1];
			if (step) {
				$scope.currentStep = step;
				
				if (typeof step.start === 'function') {
					if (step.parallel === true) parallelCount++;
					
					$scope.stepSubtitle = '';
					
					step.start().then(function(data) {
						if (typeof step.finish === 'function') {
							step.finish(data).then(nextStep, nextStep);
						} else {
							nextStep();
						}
					}, function(error) {
						if (typeof error === 'object' && error.message) {
							$scope.errors.unshift(error.message);
						}
						nextStep();
					}).finally(function() {
						if (step.parallel === true) parallelCount--;
					});
					
					if (step.parallel === true && parallelCount < maxParallel && updater.steps[currentStepIndex] && updater.steps[currentStepIndex].parallel === true) {
						//nextStep();
					}
				} else {
					nextStep();
				}
			} else {
				var user = angular.fromJson(localStorage.user);
				Model.Destinations.getSections(user.destination.id).then(function(sections) {
					if (sections.length) {
						//sections = sections.filter(function(o))
						var position = 100;
						var item = null;
						angular.forEach(sections, function(value, key) {
							if(value.status == 'active' && value.position <= position){
								item = value;
								position = value.position;
							}
						}, this);

						$rootScope.goToSection(item);
						
						$timeout(function() {
							$ionicHistory.clearHistory();
							$state.reload();
						});
					}
				});
			}
		}
		
		$scope.updater = updater;
		nextStep();
	}
})();