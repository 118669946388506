(function () {
	'use strict';

	angular
		.module('App')
		.controller('SliderController', SliderController);

	SliderController.$inject = ['$scope', '$rootScope', '$stateParams', '$timeout', 'Modals', 'Model','$sce'];
	function SliderController($scope, $rootScope, $stateParams, $timeout, Modals, Model,$sce) {
		$scope.data = {};
		$scope.activeSlide = 0;

		var controller = this;
		controller.API = null;

		controller.onPlayerReady = function(API) {
		    controller.API = API;
		};

		// Sliders
		// Options par d�faut pour le slider
		$scope.slider = null;
		$scope.pageSliderOptions = {
			observer: true,
			loop: false,
			pager: true,
            paginationClickable: true,
			resistance: true,
			resistanceRatio: 0,
			initialSlide: 0,
			iOSEdgeSwipeDetection: true,
			onSlideChangeStart: function(swiper) {
				if(swiper.slides[swiper.activeIndex].getElementsByTagName('video')[0])
					swiper.slides[swiper.activeIndex].getElementsByTagName('video')[0].pause();
				$scope.activeSlide = swiper.activeIndex;
				$scope.$apply();
			},
			onTouchStart: function() {
			},
			onSlideChangeEnd: function(swiper) {
				$scope.activeSlide = swiper.activeIndex;
				$('video').each(function() {
					$(this).get(0).pause();
					$(this).get(0).setAttribute('webkit-playsinline', 'webkit-playsinline');

				});
				if(swiper.slides[swiper.activeIndex].getElementsByTagName('video')[0])
					$scope.playing(swiper.slides[swiper.activeIndex].getElementsByTagName('video')[0]);			
 			}
		};

		$scope.playing = function(videoP){
			videoP.play().then(function() {
			    // Automatic playback started!
			  }).catch(function(error) {
			    // Automatic playback failed.
			    // Show a UI element to let the user manually start playback.
			    $scope.playing(videoP);
			  });
		};

		$scope.$on("$ionicSlides.sliderInitialized", function(event, data){
			$scope.slider = data.slider;
		});

		$scope.$on("$ionicView.afterEnter", function(event) {

			$timeout(function(){ 
				if($scope.slider.slides[$scope.slider.activeIndex].getElementsByTagName('video')[0])
					$scope.slider.slides[$scope.slider.activeIndex].getElementsByTagName('video')[0].play();
				}, 3000);

				$('video').each(function() {
					$(this).get(0).pause();
					$(this).get(0).setAttribute('webkit-playsinline', 'webkit-playsinline');

				});
			
		});

		$scope.$on("$ionicView.enter", function(event) {
			
			$timeout(function(){ 
				if($scope.slider.slides[$scope.slider.activeIndex].getElementsByTagName('video')[0])
					$scope.slider.slides[$scope.slider.activeIndex].getElementsByTagName('video')[0].play();
				}, 2000);

			
		});


		$scope.$on('$ionicView.leave', function(){
		  // Anything you can think of
		 	$('#backbutton').addClass('ng-hide');

		  	$('video').each(function() {
				$(this).get(0).pause();
			});
			
		});
		
		$scope.textHidden = false;
		$scope.toggleText = function() {
			$scope.textHidden = !$scope.textHidden;
		};
		
		$scope.selectText = function(index) {
			$scope.currentTextIndex = index;
		};
		
		$scope.previousSlide = function() {
			if ($scope.slider) {
				$scope.slider.slidePrev();
			}
		};
		
		$scope.nextSlide = function() {
			if ($scope.slider) {
				$scope.slider.slideNext();
			}
		};
		
		$scope.previousText = function() {
			var page = $scope.data.pages[$scope.activeSlide - 1];
			if (!page) return;
			
			if ($scope.currentTextIndex > 0) {
				$scope.currentTextIndex--;
			}
		};
		
		$scope.nextText = function() {
			var page = $scope.data.pages[$scope.activeSlide - 1];
			if (!page) return;
			
			if ($scope.currentTextIndex < page.texts.length - 1) {
				$scope.currentTextIndex++;
			}
		};

		$scope.data.pages = [];
		$scope.language = null;
		$scope.$on("$ionicView.beforeEnter", function(event, data) {
			$('#backbutton').addClass('ng-hide');

			if (!data.stateParams.id || !data.stateParams.item) {
				$rootScope.goHome();
				return;
			}

			var user = angular.fromJson(localStorage.user);
			Model.Destinations.getSections(user.destination.id).then(function(sections) {
				var found = false;
				if(data.stateParams.back == true){ 
					$('#backbutton').removeClass('ng-hide');
				}
				
				for (var i=0; i<sections.length; i++) {
					if (sections[i].id == data.stateParams.id && sections[i].sliders.length > 0 && sections[i].type == 'slider') {		
						loadItem(sections[i]);
						found = true;
						break;
					}
				}
				if (!found && data.stateParams.item) {
					loadItem(data.stateParams.item);
				}
			});
		});

		function loadItem(item) {
			item.sliders = item.sliders.filter(function(o){ return (o.status == 'active'); });
			if ($scope.language != $rootScope.language) {
				if ($scope.slider) {
					$scope.slider.removeAllSlides();
				}
				$scope.currentTextIndex = 0;
				$scope.textHidden = false;

				$scope.language = $rootScope.language;

					$scope.data.pages.length = 0;

						$scope.data.pages = item.sliders.slice();

						$scope.data.pages.forEach(function(page) {
							if(page.status === 'active'){
							if (page.backgroundImage.type == 'video/mp4') {
								page.backgroundImage.video = [
					                {
					                    src: $sce.trustAsResourceUrl(page.backgroundImage.url),
					                    type: 'video/mp4'
					                }
					            ];
								ImgCache.$promise.then(function() {
									ImgCache.isCached(page.backgroundImage.url, function(path, success) {	
										if (success) {
											ImgCache.getCachedFile(page.backgroundImage.url, function(src, dest) {
												page.backgroundImage.video = [
									                {
									                    src: dest.nativeURL,
									                    type: 'video/mp4'
									                }
									            ];
											});
										}
									});
								});
							}
							page.texts = page.texts.filter(function(o){ return (o.status == 'active'); });
							page.texts.forEach(function(text) {
								if (text.icon && text.icon.url) {
									var url = text.icon.url;
									ImgCache.$promise.then(function() {
										ImgCache.isCached(url, function(path, success) {
											if (success) {
												ImgCache.getCachedFileURL(url, function(src, dest) {	
													text.icon.url = dest;
												});
											}
										});
									});
								}
							});
							} //end if active
						});

						if ($scope.slider) {
							$scope.slider.slideTo($scope.activeSlide); //HACK
						}
			}
		}
	}
})();