(function () {
	'use strict';

	angular
		.module('App')
		.controller('DocumentsController', DocumentsController);

	DocumentsController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', '$timeout', '$ionicScrollDelegate', '$sce', 'Modals', 'Model'];
	function DocumentsController($scope, $rootScope, $state, $stateParams, $timeout, $ionicScrollDelegate, $sce, Modals, Model) {
		// Sliders
		// Options par défaut pour le slider
		$scope.sliders = {};
		$scope.pageSliderOptions = {
			loop: false,
			pagination: false,
			iOSEdgeSwipeDetection: true,
			initialSlide: $scope.activeSlide,
			onSlideChangeStart: function(swiper) {
				$scope.activeSlide = swiper.activeIndex;
			}
		};
		
		// Scroll to top
		$scope.scrollToTop = function() {
			$ionicScrollDelegate.scrollTop();
		};
		
		// Filters
		$scope.filters = [];
		
		// Load data
		$scope.items = [];
		$scope.language = null;
		$scope.$on("$ionicView.enter", function(event, data) {
			if (!$stateParams.item) {
				$rootScope.goHome();
				return;
			}
			
			
			var user = angular.fromJson(localStorage.user);
			Model.Destinations.getSections(user.destination.id).then(function(sections) {
				var found = false;
				for (var i=0; i<sections.length; i++) {
					if (sections[i].id == data.stateParams.id) {
						loadItem(sections[i]);
						found = true;
						break;
					}
				}
				if (!found && data.stateParams.item) {
					loadItem(data.stateParams.item);
				}
			});
		});
		
		function loadItem(item) {
			$scope.title = item.name;
			$scope.lineLength = item.lineLength;

			// Filters
			$scope.filters.length = 0;
			item.fileCategories = item.fileCategories.filter(function(o){ return (o.status != 'deleted'); });
			item.fileCategories.forEach(function(category) {
				$scope.filters.push(category);
			});
		
			// Items
			if ($scope.language != $rootScope.language) {
				$scope.language = $rootScope.language;

				$timeout(function() {
					item.files = item.files.filter(function(o){ return (o.status != 'deleted'); });
					/*$scope.items = item.files.slice();*/
					$scope.items = [];
					item.files.forEach(function(a){
					if(a.name == ""){
						a.name = a.file.url.split('/').pop();
					}
					$scope.items.push({
						length: a.length,
						name: a.name.split('.')[0],
						type: a.file.url.split('.').pop(),
						lenghtType: a.file.url.split('.').pop().length,
						file: a,
						id : a.category ? a.category.id : ''
					});
				});

				$scope.items.length = item.files.length;
				$scope.items.file = item.files;


					heightMasonry();
				});
			}

			// Reset filters
			$scope.$broadcast('iso-option', {filter:'*'});
		}

		function heightMasonry(){
			$scope.viewHeight = window.innerHeight - $("ion-view[view-title='"+$scope.title+"'] .header").height();
			$scope.$apply();
			if($scope.viewHeight == window.innerHeight){
				$timeout(function() {
					heightMasonry();
				});
			}
		}

		$scope.selectItem = function(item, index, $event) {
			//TODO:
			var type = item.file.file.type;
			var url = item.file.file.url;
			
			switch(type) {
				case 'video/mp4':
					//TODO: affichage plein écran
					$scope.showVideo(url);
					break;
				case 'image/jpeg':
				case 'image/jpg':
				case 'image/png':
				case 'image/gif':
					$scope.showImage(url);
					break;
				default:
					window.open(encodeURI('https://docs.google.com/gview?embedded=true&url='+url), '_blank', "location=yes,toolbar=yes,closebuttoncaption=X,enableViewportScale=yes,presentationstyle=pagesheet,allowInlineMediaPlayback=yes");
					//window.open(url, '_blank',"location=yes,toolbar=yes,closebuttoncaption=X,enableViewportScale=yes,presentationstyle=pagesheet,allowInlineMediaPlayback=yes");
			}
		};
		
		// Image modal
		$scope.showImage = function (url) {
			$scope.modalImages = [url];
			$scope.modalIndex = 0;
			$scope.modalImageVisible = true;
		};

		$scope.modalImageVisible = false;
		$scope.modalSliderOptions = {
			loop: false,
			pagination: false
		};
		
		$scope.closeImageModal = function() {
			$scope.modalImageVisible = false;

		};


		$scope.$on("$ionicSlides.sliderInitialized", function(event, data){
			$scope.slider = data.slider;
		});
		
		// Video modal
		$scope.showVideo = function (url) {
			$scope.modalVideoVisible = true;
			angular.element('video')[0].src = url;
			angular.element('video')[0].play();
		};

		$scope.modalVideoVisible = false;
		$scope.modalSliderOptions = {
			loop: false,
			pagination: false
		};

		$scope.modalImageVisible = false;

		$scope.closeVideoModal = function() {
			$scope.modalVideoVisible = false;
			angular.element('video')[0].pause();
			angular.element('video')[0].currentTime = 0;
		};

		$scope.trustSrc = function(src) {
			return $sce.trustAsResourceUrl(src);
		};
	}
})();